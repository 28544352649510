html {
  background: var(--bg-color);
  overscroll-behavior: none;
  touch-action: manipulation;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: var(--bg-color) !important;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/src/fonts/Montserrat-SemiBold.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Boston';
  src: url('/src/fonts/Boston-Light.otf');
  font-weight: 300;
}
@font-face {
  font-family: 'Boston';
  src: url('/src/fonts/Boston-Bold.otf');
  font-weight: 600;
}
