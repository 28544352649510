.cls-1 {
  opacity: 0.22;
}
.cls-2 {
  fill: #e8f9ff;
}
.cls-3 {
  opacity: 0.3;
}
.cls-4 {
  fill: #012e3f;
}
.cls-5 {
  fill: #5ad3ff;
}
.cls-6 {
  fill: #015473;
}
.cls-7 {
  fill: #0196cd;
}
.cls-8 {
  fill: #fff;
}
.cls-9 {
  fill: #425973;
}
.cls-10 {
  fill: #56d1ff;
}
.cls-11 {
  fill: #2dc6ff;
}
.cls-12 {
  fill: #97e3ff;
}
.cls-13 {
  fill: #23c4ff;
}
.cls-14 {
  fill: #ff3b30;
}
.cls-15 {
  fill: #4d4d4d;
}
