.App {
  text-align: center;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  /* height: 40vmin; */
  pointer-events: none;
}

.App-header {
  /* background-color: #282c34; */
  /* background-image: url("/static/shattered.png"),
    linear-gradient(200deg, #138c75, #24d8c8 70.71%); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Poppins";
}

.App-title {
  font-size: 46px;
  letter-spacing: -2.35px;
  text-shadow: 0 2px rgba(0, 0, 0, 0.2);
}

.logo {
}

.video video {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -100;
  overflow: hidden;
  /* transform: translateX(-50%) translateY(-50%); */
  /* background: url("//demosthenes.info/assets/images/polina.jpg") no-repeat;
  background-size: cover;
  transition: 1s opacity; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
